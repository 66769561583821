import React from 'react';
import {ReactSVG} from 'react-svg';
import {TagBox} from 'devextreme-react';
import classNames from 'classnames';

const AppTagBox = (props) => {
  const {
    items,
    showSelectionControls = true,
    applyValueMode = 'useButtons',
    displayExpr,
    valueExpr,
    selectedItems = null,
    changeHandler,
    placeholder,
  } = props;

  return (
    <div className={classNames('flexi-popup-select_item', {
      'is-selected' : selectedItems,
    })}>
      {!selectedItems ? (
        <ReactSVG src={'./images/figures.svg'} className={'flexi-popup-select_item__icon'}/>
      ) : null}
      <TagBox
        items={items}
        displayExpr={displayExpr}
        valueExpr={valueExpr}
        value={selectedItems}
        onValueChanged={({value}) => {
          changeHandler(value);
        }}
        showSelectionControls={showSelectionControls}
        applyValueMode={applyValueMode}
        placeholder={placeholder}
      />
    </div>
  );
}

export default AppTagBox;